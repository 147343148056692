<template>
	<div class="headerg">
		<div
			class="left_header_pc flex align_items_c"
			:class="{ headergscrolled: isScrolled }"
		>
			<div class="tab_logo pointer" @click="home()">
				<img
					src="https://res.metaera.hk/resources/assets/images/dataera/dataera.png"
					alt=""
				/>
			</div>
			<div class="align_items_c justify_content_sb tab_action">
				<div class="navList_first_pd align_items_c">
					<div
						class="tab_actionItem flex pointer"
						v-for="(item, index) in navList"
						:key="index"
						@click="toPath(item, index)"
						:class="{ tab_actionItem_active: currentPath == item.router }"
					>
						<div class="tab_actionItem_line_4"></div>

						<span>{{ item.name }}</span>
					</div>
				</div>
			</div>
		</div>
		<div
			class="pd_header_mobile flex align_items_c"
			:class="{ headergscrolled: true }"
		>
			<div class="tab_btn">
				<img
					src="https://res.metaera.hk/resources/assets/images/event/48.png"
					@click="showList()"
					alt=""
				/>
			</div>
			<div class="tab_logo">
				<img
					src="https://res.metaera.hk/resources/assets/images/logo_w.png"
					@click="
						$router.push({
							path: '/',
						})
					"
					alt=""
				/>
			</div>
		</div>

		<div class="pd_header_list" v-show="isShowList">
			<div class="pd_header_list_btn" @click="hideList()">
				<img
					src="https://res.metaera.hk/resources/assets/images/event/42.png"
					alt=""
				/>
			</div>
			<div class="tab_actionItem_line_2"></div>
			<div class="pd_header_list_1">
				<div
					class="tab_actionItem_pd flex pointer"
					v-for="(item, index) in navList"
					:key="index"
					@click="toPath(item, index)"
					:class="{ active: currentPath == item.router }"
				>
					<span>{{ item.name }}</span>
					<div class="tab_actionItem_line_1"></div>
					<div class="tab_actionItem_line_3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		isScrolled: {
			type: Boolean,
			default: false,
		},
		currentPath: {
			type: String,
			default: "/polkadot2024",
		},
	},
	created() {},
	data() {
		return {
			currentIndex: 0,
			isShowList: false,
			currentImage:
				"https://res.metaera.hk/resources/assets/images/event/pd_header_btn.png", // 初始图片
			images: {
				a: "https://res.metaera.hk/resources/assets/images/event/pd_header_btn.png", // 图片A
				b: "https://res.metaera.hk/resources/assets/images/event/pd_header_btn1.png", // 图片B
			},
			navList: [
				{
					name: "BTC储备",
					enable: false,
					router: "DataEra",
				},
				{
					name: "稳定币",
					enable: false,
					router: "/",
				},
				{
					name: "ETF",
					enable: false,
					router: "/",
				},
				{
					name: "RWA",
					enable: false,
					router: "/",
				},
				{
					name: "Meme",
					enable: false,
					router: "/",
				},
			],
		};
	},
	methods: {
		home() {
			window.open("https://www.metaera.hk/", "_blank");
		},
		toPath(item, index) {
			if (!item.enable) {
				return;
			}
			console.log(index);
			this.currentIndex = index;
			this.currentPath = item.router;
			this.$router.push({ path: item.router });
			this.isShowList = false;
		},
		showList() {
			this.isShowList = true;
		},
		hideList() {
			this.isShowList = false;
		},
		showUrl() {
			window.open("https://lu.ma/hszom0hw", "");
		},
		changeImage(imageKey) {
			this.currentImage = this.images[imageKey];
		},
	},
	watch: {
		"$route.name": function (n) {
			this.showHeader = n == "/";
			this.currentPath = n;
		},
	},
};
</script>

<style scoped>
.headergscrolled {
	background-color: black !important;
	color: white;
}
.pd_header_mobile {
	display: none;
}
.left_header_pc {
	display: block;
	height: 100vh;
	top: 0px;
	left: 0;
	width: 180px;
	margin: 0 auto;
	z-index: 100;
	position: fixed;
	background: black;
}

.tab_action {
	display: block;
	margin-top: 50px;
}
.tab_actionItem {
	width: auto;
	margin-left: 15px;
	margin-right: 15px;
	margin-top: 30px;
	height: 36px;
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
	border-radius: 4px;
	background: black;
}

.tab_actionItem_active {
	background: #0d0e12;
}

.tab_actionItem .tab_actionItem_line_4 {
	width: 2px;
	height: 16px;
	border-radius: 1px;
	opacity: 0;
	background: linear-gradient(24deg, #9945ff -5%, #19fb9b 102%);
}

.tab_actionItem_active .tab_actionItem_line_4 {
	opacity: 1;
}

.tab_actionItem img {
	margin-left: 10px;
	width: 18px;
	height: 18px;
}

.tab_actionItem span {
	margin-left: 10px;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	opacity: 0.5;
}

.tab_actionItem_active span {
	font-weight: 600;
	opacity: 1;
}

.tab_logo {
	margin-top: 50px;
	text-align: center;
}

.tab_logo img {
	height: auto;
	width: 115px;
}

.tab_btn {
	position: absolute;
	right: 50px;
}

.tab_btn img {
	height: 45px;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.headergscrolled {
		background-color: #333333 !important;
	}
	.pd_header_mobile {
		display: flex;
		height: 50px;
		top: 0px;
		left: 0;
		width: calc(100vw);
		margin: 0 auto;
		z-index: 100;
		position: fixed;
	}
	.left_header_pc {
		display: none;
	}

	.tab_logo {
		margin-top: 0px;
		margin-left: -0px;
		width: 100%;
		text-align: center;
	}

	.tab_logo img {
		height: 20px;
	}
	.tab_btn {
		position: absolute;
		left: 20px;
		width: 30px;
	}

	.tab_btn img {
		height: 20px;
	}
	.pd_header_list {
		width: 100%;
		height: 100vh;
		background: white;
		display: flex;
		flex-direction: column;
		top: 0px;
		left: 0;
		margin: 0 auto;
		z-index: 100;
		position: fixed;
	}
	.pd_header_list_btn {
		width: 20px;
		height: 20px;
		margin-top: 15px;
		margin-left: calc(100vw * 0.9);
	}
	.pd_header_list_btn img {
		width: 100%;
		height: 100%;
	}
	.tab_actionItem_line_2 {
		width: 100%;
		height: 1px;
		margin-top: 30px;
		/* margin-left: 10%; */
		margin-right: 10%;
		background: #e7e7e7;
	}
	.pd_header_list_1 {
		margin-top: 20px;
	}
	.tab_actionItem_pd {
		width: 90vw;
		height: 60px;
		margin-left: 5vw;
		display: flex;
		flex-direction: column;
	}
	.tab_actionItem_pd span {
		font-family: D-DIN;
		font-size: 18px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #999999;
	}

	.pd_header_list_1 .tab_actionItem_line_3 {
		display: none;
	}

	.pd_header_list_1 .active span {
		font-weight: bold;
		color: #333333;
	}

	.pd_header_list_1 .active .tab_actionItem_line_3 {
		display: flex;
		width: 60px;
		height: 2px;
		margin-top: -1px;
		margin-right: 10%;
		background: #333333;
	}

	.tab_actionItem_line_1 {
		width: 100%;
		height: 1px;
		margin-top: 20px;
		margin-right: 10%;
		background: #e7e7e7;
	}
}
</style>
